let yearActual = new Date().getFullYear();

const lang = {
  en: {
    "prehome.enter": "Enter",
    "PreHomeFreeEnergy.textOne": "Clean, free and inexhaustible energy.",
    "PreHomeOption.textOne": "Switching to renewable energy",
    "PreHomeOption.textTwo": "is not only the best option.",
    "PreHomeOption.textThree": "It's our only option",
    "PreHomeSaveMoney.textOne": "Saving is also knowing",
    "PreHomeSaveMoney.textTwo": "how to spend",
    "nav.home": "Home",
    "nav.products": "Products",
    "nav.about": "About us",
    "nav.contact": "Contact",
    "ourservices.title": "Our services",
    "ourservices.btnseemore": "See more",
    "contact.title": "Contact",
    "contact.subtitle": "Get in touch for any question",
    "contact.subtitleform": "Your Details",
    "contact.contactinformation": "Contact details",
    "contact.form.name": "Name",
    "contact.form.phone": "Phone",
    "contact.form.email": "Email",
    "contact.form.message": "Message",
    "contact.form.send": "Send",
    "contact.form.ok": "Your message has been sent successfully.",
    "contact.form.error": "An unknown error has occurred. Please try again later",
    "aboutus.title": "About us",
    "aboutus.subtitle": " ",
    "aboutus.paragraphone":
      "Inspired by the force of nature I created VerdeAGOSTINI, a company dedicated 100% to renewable energy. In my passion I found a balance between generating great savings in my clients and taking care of my favorite place, the environment.",
    "aboutus.paragraphtwo":
      "It is a venture, in which I am constantly training to be at the forefront of clean energy technology, creating and innovating. Growing by leaps and bounds thanks to desire, focus, effort, commitment and dedication, VerdeAGOSTINI provides first-rate sustainable energy services.",
    "section.check1": "Return of investment in the short term",
    "section.check2": "Preserves temperature up to 72 hours",
    "section.check3": "Water reaches between 70 ° C and 100 ° C",
    "section.check4": "100% Ecological does not generate CO2",
    "section.check5": "Proven technology in more than 100 countries",
    "section.check6": "Up to 80% savings",
    "section.check7": "Noiseless",
    "section.check8": "Resistant to rain wind and hail",
    "section.check9": "Guaranteed equipment",
    "section.check10": "25 years of useful life",
    "footer.yamildiego": "Developed by Yamil Diego",
    "footer.copyrightshort": "© " + yearActual + " VerdeAgostini",
    "footer.copyright": "© " + yearActual + " VerdeAgostini - All rights reserved.",
  },
  es: {
    "prehome.enter": "Ingresar",
    "PreHomeFreeEnergy.textOne": "Energía limpia, gratis e inagotable.",
    "PreHomeOption.textOne": "Cambiar a energías renovables,",
    "PreHomeOption.textTwo": "no es solo la mejor opción.",
    "PreHomeOption.textThree": "Es nuestra única opción",
    "PreHomeSaveMoney.textOne": "Ahorrar no es solo guardar",
    "PreHomeSaveMoney.textTwo": "sino saber gastar.",
    "nav.home": "Inicio",
    "nav.products": "Productos",
    "nav.about": "Nosotros",
    "nav.contact": "Contacto",
    "ourservices.title": "Nuestros servicios",
    "ourservices.btnseemore": "Ver más",
    "contact.title": "Contacto",
    "contact.subtitle": "Póngase en contacto por cualquier consulta",
    "contact.contactinformation": "Datos de contacto",
    "contact.subtitleform": "Tus datos",
    "contact.form.name": "Nombre",
    "contact.form.phone": "Teléfono",
    "contact.form.email": "Correo electrónico",
    "contact.form.message": "Mensaje",
    "contact.form.send": "Enviar",
    "contact.form.ok": "La consulta se envio con éxito, te responderemos a la brevedad.",
    "contact.form.error": "Ups ha ocurrido un error en nuestros servidor, contacte al administrador del sistema.",
    "aboutus.title": "¿Quienes somos?",
    "aboutus.subtitle": " ",
    "aboutus.paragraphone":
      "Inspirado por la fuerza de la naturaleza creé VerdeAGOSTINI, una empresa dedicada 100% a la energía renovable. En mi pasión encontré un equilibrio entre generar grandes ahorros en mis clientes y cuidar mi lugar preferido, el medioambiente.",
    "aboutus.paragraphtwo":
      "Es un emprendimiento en el que me estoy capacitando constantemente para estar a la vanguardia de la tecnología en energía limpia, creando e innovando. Creciendo a pasos agigantados gracias al deseo, enfoque, esfuerzo, compromiso y dedicación, VerdeAGOSTINI brinda servicios energéticos sustentables de primer nivel.",
    "section.check1": "Retorno de inversión a corto plazo",
    "section.check2": "Conserva la temperatura hasta 72 hs",
    "section.check3": "El agua alcanza entre 70°C y 100°C",
    "section.check4": "100% Ecológico no genera CO2",
    "section.check5": "Tecnología probada en mas de 100 paises",
    "section.check6": "Hasta 80% de ahorro",
    "section.check7": "Silencioso",
    "section.check8": "Resistente a lluvia viento y granizo",
    "section.check9": "Equipos con garantía",
    "section.check10": "25 años de vida útil",
    "footer.yamildiego": "Desarrollado por Yamil Diego",
    "footer.copyrightshort": "© " + yearActual + " VerdeAgostini",
    "footer.copyright": "© " + yearActual + " VerdeAgostini - Todos los derechos reservados.",
  },
};

export default lang;
